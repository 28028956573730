/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~leaflet/dist/leaflet.css";

* {
  text-transform: none;
}

.modalClass {
  --border-radius: 0.4rem;
}

// .custome-alert .alert-wrapper {
//   background: black;
// }

.custome-alert .alert-wrapper .alert-button-role-Delete {
  color: red !important;
}

.custome-alert .alert-wrapper .alert-button-role-cancel {
  color: black;
}

.custome-alert .alert-message {
  ion-item {
    width: 100%;
  }
}

.spinner {
  --spinner-color: var(--custome-text-color);
}

.toast_alert {
  color: black;
  --border: 1px solid black;
}
